import React, {useState, useEffect} from "react"

import styles from "../../styles/main.module.css"

import fish from "../resources/fish.png"
import complete from "../resources/complete.png"
import lizard from "../resources/lizard.png"
import macrame from "../resources/macrame.png"

export default function OrigamiMacrame() {
    const [width, setWidth] = useState(null)

    // run only on initialization
    useEffect(() => {
        setWidth(992)

        const resizeListener = () => {
            setWidth(window.innerWidth)
        };

        // returns a cleanup
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }

    }, [])

    const third = {
        height: width >= 992 ? "30%" : "90%", 
        width: width >= 992 ? "30%" : "90%",
        marginLeft: 5,
        marginRight: 5,
        alignSelf: "center",
    }

    return (
      <React.Fragment>
        <h2>DIY Decorations</h2>
        <hr className={styles.top_seperator} />
        <section style={{
            marginBlock: 25,
        }}>
        <p>Hey! I wanted to share some Origami and Macrame decorations I've been making. I used to do Origami as a kid, and decided that it would be nice and a e s t h e t i c to spice up my room with an Origami Scene. I also decided to attempt a Macrame. I've never done Macrame before, but it looks pretty nice. Below is my attempt at some daisies in MIT colors.</p>
        <p>It's honestly pretty neat doing DIY decorations. I had some fun with this, especially with the Origami (though my paper is atrocious-you may note that I followed a Kade Chan tutorial for the Koi Fish, but couldn't add the final details due to the size of my paper plus my lack of skill). I'm aiming for a waterfall of sorts with some cranes lizards and fish inhabiting the environment in my final picture.</p>
        <p>If I have time, I'd like to build a thing (make a thing) that is completely new every week (or at least do something new every week). I have a couple FPGA projects in mind, but also I was thinking of trying to pick up some Chinese calligraphy. I'm pretty busy, but something artistic I think does me good as a small side-hobby. Beyond that maybe I'll try painting, or learning how to solve a Rubiks cube, or making 3D animations with Blender. Or... actually I might try and make some tutorials on youtube for certain music production tasks. This last thing is something I've been wanting to do for a while, but desktop audio recording drivers on Mac (Catalina) are busted: it's a real pain.</p>
        </section>
        <section style={{
            alignSelf: "center",
            alignContent: "flex",
            flexDirection: width >= 992 ? "row" : "column",
            marginBottom: 25,
        }}>
            <img src={fish} style={third} />
            <img src={lizard} style={third} />
            <img src={macrame} style={third} />
        </section>
        <section style={{marginBottom: 25}}>
            <img src={complete} style={{
                width: "90%"
            }}/>
        </section>
        <div className={styles.phone_home}>
          <a href="/">Click me to return home.</a>
        </div>
      </React.Fragment>
    )
}
